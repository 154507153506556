<template>
  <div class="download">
    <div class="download-wrapper">
      <div class="download-banner"></div>
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>曝光台</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="download-concrete" v-if="dataSheetList.length > 0">
          <div class="list-content">
            <div class="content-title">
              <p class="caption">标题</p>
              <p class="department">发布部门</p>
              <p class="time">发布时间</p>
            </div>
            <ul class="list-ul">
                <li class="list-li" v-for="item in dataSheetList" :key="item.industryDynamicId" @click="tapJumpDetail(item.industryDynamicId)">
                  <p class="li-caption">{{item.title}}</p>
                  <p class="li-department">{{item.source ? item.source : '洛阳市洛阳学车'}}</p>
                  <p class="li-time">{{item.createDate}}</p>
                </li>
            </ul>
          </div>
        </div>
        <div class="newest-page" v-if="dataSheetList.length > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageObj.total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { querySimpleIndustryDynamics } from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: 'Download',
  data () {
    return {
      // 分页信息
      pageObj: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      // 曝光台列表
      dataSheetList: []
    }
  },
  computed: {
  },
  created () {
    // 曝光台列表
    this.getQuerySimpleIndustryDynamics();
  },
  methods: {
    // 曝光台列表 type: 2
    getQuerySimpleIndustryDynamics () {
      querySimpleIndustryDynamics({
        isPublish: 1,
        pageIndex: this.pageObj.pageIndex,
        pageSize: this.pageObj.pageSize,
        type: 2
      }).then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(ele => {
            ele.createDate = formatTime(ele.createDate)
          })
          this.dataSheetList = res.data.list
          this.pageObj.total = res.data.count
        }
      })
    },
    // 当前currentPage 改变时会触发
    handleCurrentChange (curPage) {
      this.pageObj.pageIndex = curPage
      this.getQuerySimpleIndustryDynamics()
    },
    // 跳转详情页
    tapJumpDetail (id) {
      this.$router.push({
        name:'Details',
        query: {
          id,
          type: 'exposure'
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
